import React, { Suspense, lazy } from 'react';
// import Page from "../components/Page";
import "../assets/scss/style.scss";
import "../assets/scss/animate.css";

const Loader = () => (
	<div className="App">
		<span className="loader"/>
	</div>
);
const Page = lazy(() => {
	return Promise.all([
		import("../components/Page"),
		new Promise(resolve => setTimeout(resolve, 1000))
	])
		.then(([moduleExports]) => moduleExports);
});
export default function App() {
	return (
		<Suspense fallback={<Loader />}>
				<Page/>
		</Suspense>
	);
}
