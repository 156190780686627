import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './assets/languages/en.json';
import translationDE from './assets/languages/de.json';

// the translations
const resources = {
	en: {
		translation: translationEN
	},
	de: {
		translation: translationDE
	}
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: "de",
		fallbackLng: 'en',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});
export default i18n;
